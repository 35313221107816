import { cn } from '@/lib/utils';
import { Loader2 } from 'lucide-react';
import * as React from 'react';

type SpinnerProps = React.PropsWithChildren & {
  className?: string;
  loading?: boolean;
};

const Spinner = (props: SpinnerProps) => {
  const { className, children, loading } = props;

  if (!loading) {
    return children;
  }

  const spinner = <Loader2 className={cn('h-4 w-4 animate-spin', className)} />;

  if (children === undefined) return spinner;

  return (
    <span className="relative flex items-center justify-center">
      {
        /**
         * `display: contents` removes the content from the accessibility tree in some browsers,
         * so we force remove it with `aria-hidden`
         */
      }
      <span
        aria-hidden
        style={{ display: 'contents', visibility: 'hidden' }}
        {
          // Workaround to use `inert` until https://github.com/facebook/react/pull/24730 is merged.
          ...{ inert: true ? '' : undefined }
        }
      >
        {children}
      </span>

      <span className="absolute inset-0 flex items-center justify-center">
        {spinner}
      </span>
    </span>
  );
};

Spinner.displayName = 'Spinner';

export { Spinner };
export type { SpinnerProps };
