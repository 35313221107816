import { Chain } from 'viem';

export const mint: Chain = {
  id: 185,
  name: 'Mint',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.mintchain.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Mint Mainnet',
      url: 'https://explorer.mintchain.io',
    },
  },
  testnet: false,
};

export const mintSepolia: Chain = {
  id: 1687,
  name: 'Mint Sepolia Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: ['https://sepolia-testnet-rpc.mintchain.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Mint Sepolia Testnet',
      url: 'https://sepolia-testnet-explorer.mintchain.io',
    },
  },
  testnet: true,
};
