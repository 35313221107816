import { useAddress } from '@/hooks/use_address';
import { buildConnectUrlFromInitData, joyIdClient } from '@/lib/joy_id';
import { initData } from '@/lib/tg';
import { useMutation } from '@tanstack/react-query';
import twa from '@twa-dev/sdk';
import { toast } from 'sonner';

export const useConnect = () => {
  const { setAddress } = useAddress();
  return useMutation({
    mutationFn: async () => {
      const { url, token } = buildConnectUrlFromInitData(initData);
      twa.openLink(url);
      const address = await joyIdClient.getConnectedAddress(token);
      await setAddress(address);
      return address;
    },
    onError: err => {
      console.error(err);
      toast.error(err.message);
    },
  });
};
