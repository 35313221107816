import { useMutation } from '@tanstack/react-query';
import twa from '@twa-dev/sdk';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { Address } from 'viem';

const ADDRESS_KEY = 'address';

// Undefined for the initial state, and null for the disconnected state.
const addressAtom = atom<Address | undefined | null>(undefined);

const asyncAddressAtom = atom(
  async (get) => {
    let address = get(addressAtom);
    if (address) {
      return address;
    }
    if (address === undefined) {
      address = await getAddressFromCloudStorage(ADDRESS_KEY);
    }
    return address;
  },
  async (get, set, address: Address | undefined | null) => {
    console.log('set address', address);
    set(addressAtom, address);
    if (address) {
      await saveAddressToCloudStorage(ADDRESS_KEY, address);
    } else {
      await removeAddressFromCloudStorage(ADDRESS_KEY);
    }
  },
);

export const useAddress = () => {
  const [address, setAddress] = useAtom(asyncAddressAtom);
  const removeAddress = () => setAddress(null);
  return {
    address,
    setAddress,
    removeAddress,
  };
};

function getAddressFromCloudStorage(key: string) {
  return new Promise<Address | undefined>((resolve) => {
    try {
      twa.CloudStorage.getItem(key, (err, result) => {
        if (err) {
          console.debug(`failed to recover address from cloud storage: ${err}`);
        }
        resolve((result || undefined) as Address | undefined);
      });
    } catch (err) {
      console.error(`failed to recover address from cloud storage: ${err}`);
      resolve(undefined);
    }
  });
}

function saveAddressToCloudStorage(key: string, address: Address) {
  return new Promise<void>((resolve) => {
    try {
      twa.CloudStorage.setItem(key, address, (err, result) => {
        if (err) {
          console.error(`failed to save address to cloud storage: ${err}`);
        }
        if (!result) {
          console.error(`failed to save address to cloud storage: unknown error`);
        }
        resolve();
      });
    } catch (err) {
      console.error(`failed to save address to cloud storage: ${err}`);
      resolve();
    }
  });
}

function removeAddressFromCloudStorage(key: string) {
  return new Promise<boolean>((resolve) => {
    try {
      twa.CloudStorage.removeItem(key, (err, result) => {
        if (err) {
          console.error(`failed to remove address from cloud storage: ${err}`);
        }
        resolve(Boolean(result));
      });
    } catch (err) {
      console.error(`failed to remove address from cloud storage: ${err}`);
      throw err;
    }
  });
}
