import { config } from '@/config';
import { InitData } from '@/lib/tg';
import { buildConnectUrl, buildSignTxURL as _buildSignTxURL, TransactionRequest } from '@joyid/miniapp';
import { Address, Chain, Hash, Hex, keccak256 } from 'viem';
import wretch from 'wretch';
import { delay, retry } from 'wretch/middlewares';

enum Action {
  Connect,
  SignMsg,
  SendTx,
}

const BASE_INIT = {
  name: config.joyId.appName,
  logo: config.joyId.logo,
  joyidAppURL: config.joyId.appUrl,
};

function generateToken(data: InitData, action: Action) {
  const hash = keccak256(`0x${Number(data.parsedUser.id).toString(16)}` as Hex).substring(2);
  const rand = [...Array(8)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
  switch (action) {
    case Action.Connect:
      return `conn${hash}${rand}`;
    case Action.SignMsg:
      return `sign${hash}${rand}`;
    default:
      return `send${hash}${rand}`;
  }
}

export function buildConnectUrlFromInitData(data: InitData) {
  if (!data.appProps?.chainId) {
    throw new Error('start param not found');
  }
  const chainId = data.appProps.chainId;
  const chain = config.supportedChains.find(chain => chain.id.toString() === chainId.toString());
  if (!chain) {
    throw new Error('Chain not found');
  }
  const token = generateToken(data, Action.Connect);
  return {
    token,
    url: buildConnectUrl({
      ...BASE_INIT,
      network: { ...chain, chainId },
      miniAppToken: token,
      callbackUrl: config.joyId.callbackUrl,
    }),
  };
}

export type SignTxParams = {
  address: Address;
  chain: Chain;
  tx: TransactionRequest;
};

export function buildSignTxURL(data: InitData, { address, chain, tx }: SignTxParams) {
  const token = generateToken(data, Action.Connect);
  const url = _buildSignTxURL({
    ...BASE_INIT,
    signerAddress: address,
    callbackUrl: config.joyId.callbackUrl,
    miniAppToken: token,
    isSend: true,
    network: { chainId: chain.id, name: chain.name },
    tx,
  });
  return { token, url };
}

export class JoyIdClient {
  client = wretch(config.joyId.callbackUrl).middlewares([
    delay(2000),
    retry({ delayTimer: 2000, maxAttempts: 60 }),
  ]);
  private getMessage<T>(token: string) {
    return this.client.url('/messages/token').post({ token }).json<T>();
  }
  async getConnectedAddress(token: string) {
    const { address } = await this.getMessage<{ address: string }>(token);
    if (address === 'rejected') {
      throw new Error('User rejected the connection');
    }
    return address as Address;
  }
  async getTxHash(token: string) {
    const { txHash } = await this.getMessage<{ txHash: string }>(token);
    if (txHash === 'rejected') {
      throw new Error('User rejected the transaction');
    }
    return txHash as Hash;
  }
}

export const joyIdClient = new JoyIdClient();
