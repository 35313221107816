import { Badge } from '@/components/ui/badge';
import { Skeleton } from '@/components/ui/skeleton';
import { Collection } from '@candysea/apis/candymint/v1/message/collection_pb';
import { Polygon } from 'cryptocons/src/components/Polygon';
import { Stars } from 'lucide-react';
import { FC } from 'react';

type Props = {
  collection: Collection;
};

export const PageHeader: FC<Props> = ({ collection }) => {
  return (
    <header className="grid grid-cols-[auto_1fr] grid-rows-[auto_auto] items-center gap-x-3 px-4 pt-2">
      <div className="row-span-2">
        <img src={collection.coverUri} alt={collection.name} className="size-[60px] rounded-md" />
      </div>
      <h2 className="text-lg font-semibold">{collection.displayName}</h2>
      <div className="flex items-center gap-2 text-sm">
        <Badge variant="outline" className="flex items-center gap-1">
          <Polygon className="size-4" />
          {collection.contractAddress.slice(0, 6)}...{collection.contractAddress.slice(-4)}
        </Badge>
        <Badge variant="outline" className="flex items-center gap-1">
          <Stars className="size-3" />
          On Chain
        </Badge>
      </div>
    </header>
  );
};

export const PageHeaderSkeleton = () => {
  return (
    <header className="grid grid-cols-[auto_1fr] grid-rows-[auto_auto] items-center gap-x-3 px-4 pt-2">
      <div className="row-span-2">
        <Skeleton className="size-[60px] rounded-md" />
      </div>
      <Skeleton className="w-20 h-6" />
      <div className="flex items-center gap-2 text-sm">
        <Skeleton className="w-16 h-4" />
      </div>
    </header>
  );
};
