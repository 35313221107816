import twa from '@twa-dev/sdk';
import { useEffect, useState } from 'react';

export const useViewportHeight = () => {
  const [height, setHeight] = useState(twa.viewportStableHeight);
  useEffect(() => {
    twa.onEvent('viewportChanged', ({ isStateStable }) => {
      if (isStateStable) {
        setHeight(twa.viewportStableHeight);
      }
    });
  }, []);
  return {
    height,
    styles: {
      height: `${height}px`,
    },
  };
};
