import { config } from '@/config';
import { NftService } from '@candysea/apis/candymint/v1/nft_service_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';

export const publicTransport = createConnectTransport({
  baseUrl: config.api.gateway,
});

export const nftServiceClient = createPromiseClient(NftService, publicTransport);
