import { config } from '@/config';
import { cn } from '@/lib/utils';
import numeral from 'numeral';
import { FC } from 'react';
import { formatUnits } from 'viem';

type Props = {
  chainId: number | string;
  value: bigint | string;
  className?: string;
  valueClassName?: string;
  nameClassName?: string;
};

export const EvmCurrency: FC<Props> = ({
  value,
  chainId,
  valueClassName,
  nameClassName,
  className,
}) => {
  const chain = getChainById(chainId);
  if (!chain) {
    return <span>INVALID VALUE</span>;
  }
  const d4 = numeral(formatUnits(BigInt(value), chain.nativeCurrency.decimals))
    .format(
      '0, 0.0[0000]',
      Math.trunc,
    );
  return (
    <span
      className={cn('inline-flex items-center gap-1 font-semibold', className)}
    >
      <span className={cn('currency-value', valueClassName)}>
        {d4}
      </span>
      <span
        className={cn('currency-name text-muted-foreground', nameClassName)}
      >
        {chain.nativeCurrency.symbol}
      </span>
    </span>
  );
};

function getChainById(chainId: number | string) {
  return config.supportedChains.find(chain => chain.id.toString() === chainId.toString())!;
}
