import { AccountButton } from '@/components/account';
import { PageHeader } from '@/components/header';
import { Spinner } from '@/components/spinner';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useViewportHeight } from '@/hooks/use_viewport_height';
import { initData } from '@/lib/tg';
import { getCollection } from '@candysea/apis/candymint/v1/collection_service-CollectionService_connectquery';
import { Collection_Type } from '@candysea/apis/candymint/v1/message/collection_pb';
import { useSuspenseQuery } from '@connectrpc/connect-query';
import { FC, lazy, Suspense } from 'react';

const CompositeItems = lazy(() => import('@/components/templates/composite'));
const DefaultItems = lazy(() => import('@/components/templates/default'));

export const App: FC = () => {
  const { data: collection } = useSuspenseQuery(getCollection, {
    collectionName: initData.appProps.collectionName,
    chainId: initData.appProps.chainId.toString(),
  });
  const { styles } = useViewportHeight();
  return (
    <div
      className="relative flex flex-col max-h-screen gap-4 overflow-y-auto"
      style={styles}
    >
      <PageHeader collection={collection} />
      <Tabs defaultValue="items" className="flex-1 px-4">
        <TabsList className="w-full mb-2">
          <TabsTrigger value="items" className="flex-1">Items</TabsTrigger>
          <TabsTrigger value="info" className="flex-1">Info</TabsTrigger>
        </TabsList>
        <TabsContent value="items">
          <Suspense fallback={<Spinner loading />}>
            <>
              {(() => {
                switch (collection.type) {
                  case Collection_Type.PNG_COMPOSITE:
                  case Collection_Type.SVG_COMPOSITE:
                    return <CompositeItems collection={collection} />;
                  case Collection_Type.DEFAULT:
                    return <DefaultItems collection={collection} />;
                  default:
                    return <div>Not support</div>;
                }
              })()}
            </>
          </Suspense>
        </TabsContent>
        <TabsContent value="info">
          {collection.description}
        </TabsContent>
      </Tabs>
      <div className="py-3 text-xs text-center text-muted-foreground">Powered by CandyMint</div>
      <div className="sticky mx-auto bottom-4">
        <Suspense fallback={'Loading...'}>
          <AccountButton chainId={initData.appProps.chainId.toString()} />
        </Suspense>
      </div>
    </div>
  );
};
