import { initData } from '@/lib/tg';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';
import { Button } from 'react-day-picker';
import { ErrorBoundary as _ErrorBoundary } from 'react-error-boundary';

export const ErrorBoundary: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => {
        return (
          <_ErrorBoundary
            onReset={reset}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                There was an error: {error.message}
                <pre>{JSON.stringify(initData, null, 2)}</pre>
                <Button onClick={() => resetErrorBoundary()}>Try again</Button>
              </div>
            )}
          >
            {children}
          </_ErrorBoundary>
        );
      }}
    </QueryErrorResetBoundary>
  );
};
