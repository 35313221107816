import { EvmCurrency } from '@/components/currency';
import { Spinner } from '@/components/spinner';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { config } from '@/config';
import { useAddress } from '@/hooks/use_address';
import { useConnect } from '@/hooks/use_connect';
import { useQuery } from '@tanstack/react-query';
import { Polygon } from 'cryptocons/src/components/Polygon';
import { Wallet } from 'lucide-react';
import { useState } from 'react';
import { Address, createPublicClient, http } from 'viem';
import { getBalance } from 'viem/actions';

export const AccountButton = ({ chainId }: { chainId: string }) => {
  const { address } = useAddress();
  return address
    ? <EvmAccountButton chainId={chainId} address={address} />
    : <ConnectButton />;
};

const ConnectButton = () => {
  const connect = useConnect();
  return (
    <Button
      onClick={() => connect.mutate()}
      disabled={connect.isPending}
      className="flex items-center gap-2 rounded-full bg-gradient-to-b from-[#FF39DF] to-[#FAA0A0]"
      size="lg"
    >
      <Spinner loading={connect.isPending}>
        <Wallet className="size-4" />
      </Spinner>
      Connect Wallet
    </Button>
  );
};

const EvmAccountButton = ({ chainId }: { address: Address; chainId: string }) => {
  const { address, removeAddress } = useAddress();
  const [open, setOpen] = useState(false);
  const { data: balance } = useQuery({
    queryKey: ['balance', address],
    queryFn: async () => {
      const publicClient = createPublicClient({
        chain: config.supportedChains.find(chain => chain.id.toString() === chainId),
        transport: http(),
      });
      if (!address) return 0n;
      return await getBalance(publicClient, {
        address,
      });
    },
  });
  return (
    <Sheet open={open} onOpenChange={setOpen} modal>
      <SheetTrigger asChild>
        <Button className="flex items-center gap-2 rounded-full shadow-lg">
          <div className="flex items-center gap-1">
            <Polygon className="size-4" />
            <EvmCurrency value={balance ?? 0n} chainId={chainId} />
          </div>
          <Separator orientation="vertical" />
          {address?.slice(0, 6)}...{address?.slice(-4)}
        </Button>
      </SheetTrigger>
      <SheetContent side="bottom">
        <Button
          className="flex items-center gap-2 rounded-full shadow-lg"
          onClick={async () => {
            removeAddress();
            setOpen(false);
          }}
        >
          Disconnect
        </Button>
      </SheetContent>
    </Sheet>
  );
};
